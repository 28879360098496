<template>
  <ion-page @click="clickPage">
    <ion-header mode="ios" class="baseLayout-Header" :style="mediumHeader? (greyHeader ? 'background-color: #A9A9A9' : 'background-color: #FF8135') : bgColor? 'background-color: '+bgColor : ''">
      <ion-toolbar mode="ios" :color="mediumHeader? (greyHeader ? 'tertiary' : 'medium') : ''" :style="bgColor? '--background: '+bgColor : ''">
        <ion-buttons slot="start">
          <div v-if="pageDefaultBackLink">
            <ion-back-button
              ref="backButton"
              :class="[backButtonClass(), backButtonTextColorClass()]"
              :text="backButtonText"
              icon=""
              v-if="!ignoreHistory"
              :default-href="pageDefaultBackLink"
              style="margin-left: 12px"
            ></ion-back-button>
            <ion-back-button
              ref="backButton"
              :class="[backButtonClass(), backButtonTextColorClass()]"
              v-else
              :text="backButtonText"
              icon=""
              @click.prevent="back()"
              default-href=""
              style="margin-left: 12px"
            ></ion-back-button>
          </div>
          <!-- <ion-menu-button
            color="primary"
            v-show="showMenuButton"
          ></ion-menu-button> -->
          <!-- <ion-text class="pl-4 font-weight-bold">{{ pageTitle }}</ion-text> -->
        </ion-buttons>
        <ion-buttons v-if="showFilter" class="ml-auto" slot="end">
          <ion-button @click="searchCondition()">
            <!-- <ion-icon slot="icon-only" :ios="Icon.ellipsisHorizontal" :md="Icon.ellipsisVertical"></ion-icon> -->
            <ion-icon slot="icon-only" :ios="Icon.searchOutline" :md="Icon.searchOutline"></ion-icon>
          </ion-button>
          <ion-button @click="addItem()">
            <ion-icon slot="icon-only" :ios="Icon.addCircle" :md="Icon.addCircle"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showDownload" class="ml-auto" slot="end">
          <ion-button @click="downloadCoupon">
            <div class="download"></div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showLanguage" class="ml-auto" slot="end">
          <ion-button @click="changeLanguage">
            <div class="switch_lang"></div>
          </ion-button>
          <!-- <ion-button @click="changeLanguage()">
            <ion-icon slot="icon-only" :ios="Icon.ellipsisHorizontal" :md="Icon.ellipsisVertical"></ion-icon>
            <ion-icon slot="icon-only" :ios="Icon.languageOutline" :md="Icon.languageOutline"></ion-icon>
          </ion-button> -->
        </ion-buttons>
        <ion-buttons v-if="showCustom" class="ml-auto" slot="end">
          <slot name="actions-end">
          </slot>
        </ion-buttons>
        <ion-buttons v-if="showDots" class="ml-auto" slot="end">
          <ion-button @click.stop="clickMore">
            <div class="mine_more"></div>
          </ion-button>
        </ion-buttons>
        <ion-title class="ion-no-padding page-title">{{ pageTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content name="content" :class="`ion-padding ${className}`" :scroll-events="true" :forceOverscroll="false">
      <slot name="content" />
    </ion-content>
    <ion-footer v-if="showFooter" :style="{height: footerHeight}" class="ion-no-border">
      <slot name="footer"></slot>
    </ion-footer>
  </ion-page>
</template>

<script>
import { ref } from 'vue';
import {
  ellipsisHorizontal,
  ellipsisVertical,
  searchOutline,
  chevronBackOutline,
  addCircle,
  languageOutline,
  receiptOutline,
} from 'ionicons/icons';

import {
  IonText,
  IonTitle,
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFooter,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import { Preferences as Storage } from '@capacitor/preferences';

export default {
  name: 'BaseLayout',
  props: {
    pageTitle: {
      type: String,
      required: false,
    },
    pageDefaultBackLink: {
      type: String,
      required: false,
    },
    showMenuButton: {
      type: Boolean,
      default: true,
    },
    ignoreHistory: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      required: false,
    },
    pointsHistoryLink: {
      type: String,
      required: false,
    },
    showLanguage: {
      type: Boolean,
      default: false,
    },
    showCustom: {
      type: Boolean,
      default: false,
    },
    showDots: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    backButtonText: {
      type: String,
      required: false,
      default: ""
    },
    mediumHeader: {
      type: Boolean,
      default: false
    },
    greyHeader: {
      type: Boolean,
      default: false
    },
    whiteBackButton: {
      type: Boolean,
      default: false
    },
    showDownload: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
    footerHeight: {
      type: String,
      default: '65px'
    }
  },
  components: {
    IonText,
    IonTitle,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonFooter,
    IonBackButton,
    IonButtons,
    IonMenuButton,
    IonButton,
    IonIcon,
  },
  setup() {
    const router = useRouter();

    const Icon = {
      ellipsisHorizontal,
      ellipsisVertical,
      searchOutline,
      chevronBackOutline,
      addCircle,
      languageOutline,
      receiptOutline,
    };
    const showMore = ref(false);

    return {
      router,
      Icon,
      showMore,
    };
  },
  mounted() {
    this.showMore = false;
  },
  unmounted() {
    this.showMore = false;
  },
  watch: {
    showMore(newValue) {
      this.$emit('showMoreChanged', newValue);
    },
  },
  methods: {
    back() {
      this.router.push(this.pageDefaultBackLink);
    },
    searchCondition() {
      this.emitter.emit('topSearch');
    },
    addItem() {
      this.emitter.emit('topAdd');
    },
    changeLanguage() {
      if (this.$i18n.locale != 'zh') {
        this.$i18n.locale = 'zh';
      } else {
        this.$i18n.locale = 'en';
      }

      Storage.set({
        key: 'user_language',
        value: this.$i18n.locale,
      });
      
      this.emitter.emit('changeLanguage');
    },
    backButtonTextColorClass() {
      return this.whiteBackButton ? 'back-button-text-white' : 'back-button-text-default';
    },
    backButtonClass() {
      return this.whiteBackButton ? 'white-back-button' : 'back-button';
    },
    downloadCoupon() {
      this.$emit('download-coupon');
    },
    clickMore() {
      this.showMore = !this.showMore;
    },
    clickPage() {
      if (this.showMore) {
        this.showMore = false;
      }
    },
  },
};
</script>
<style scoped>
.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}
.switch_lang {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/switch_lang.svg');
}
.back-button {
  background-image:  url('/assets/image/back.png');
  background-size: fit;
  background-repeat: no-repeat;
  background-position: left center;
  min-width: 32px;
  margin-left: 16px;
}
.white-back-button {
  background-image:  url('/assets/image/back_white.png');
  background-size: fit;
  background-repeat: no-repeat;
  background-position: left center;
  min-width: 32px;
  margin-left: 16px;
}
.download {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/download.png');
}
.mine_more {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/more.png');
}
.page-title {
  width: 50%;
  left: 25%;
}
ion-back-button::part(text) {
  font-weight: 700;
  font-size: 1.1rem;
  margin-left: 18px;
  padding-bottom: 3px;
}
.back-button-text-white::part(text) {
  margin-left: 45px;
  color: #ffffff;
}
.back-button-text-default::part(text) {
  margin-left: 45px;
  color: #333333;
}
</style>