<template>
  <ion-page @click="clickPage">
    <ion-header v-if="tabName=='StoreSearch'" mode="ios" class="marginLeft-10px">
      <ion-toolbar mode="ios">
        <ion-buttons class="search-bar">
          <div class="mag_glass"></div>
          <ion-input
            clear-input
            autofocus
            :placeholder="$t('Search Store')"
            v-model="searchText"
            @ionChange="onSearchTextChange()"
          ></ion-input>
        </ion-buttons>
        <ion-buttons class="ml-auto" slot="end" style="margin-right: 20px;">
          <ion-button @click="startScan()">
            <div class="scanQR"></div>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <transition name="wallet" v-else-if="tabName=='Wallet'">
      <ion-header mode="ios" class="wallet_header" v-if="!hideHeader">
        <ion-toolbar mode="ios">
          <ion-label class="wallet_name">Orange Wallet</ion-label>
          <ion-buttons class="ml-auto" slot="end" style="margin-right: 10px;">
            <ion-button @click="startScan()">
              <div class="wallet_scan"></div>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
    </transition>
    <ion-header v-else-if="tabName=='Mines'" mode="ios" class="mines_header">
      <ion-toolbar mode="ios">
        <ion-buttons class="ml-auto" slot="end" style="margin-right: 13px;">
          <ion-button @click="clickSwitchLang">
            <div class="mine_switch_lang"></div>
          </ion-button>
          <ion-button @click.stop="clickMore">
            <div class="mine_more"></div>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-header v-else-if="tabName=='PointMall'" mode="ios" class="mines_header">
      <ion-toolbar mode="ios">
        <ion-buttons slot="start">
          <ion-back-button
            ref="backButton"
            class="white-back-button back-button-text-white"
            :text="backButtonText"
            icon="placeholder"
            default-href="/"
          ></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div v-if="tabName=='Mines'" class="mine_bg" ></div>
    <div v-if="tabName=='PointMall'" class="pointmall_bg" ></div>
    <ion-content name="content" :scroll-events="true" :forceOverscroll="false" :class="{'mines_content': tabName === 'Mines' || tabName === 'PointMall' }">
      <slot name="content" />
    </ion-content>
    <ion-footer v-if="showFooter" class="height-65">
      <slot name="footer"></slot>
    </ion-footer>
  </ion-page>
</template>

<script>
import { ref } from 'vue';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFooter,
  IonButtons,
  IonButton,
  IonInput,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonText,
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import { Preferences as Storage } from '@capacitor/preferences';

export default {
  name: 'BaseLayout2',
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    backButtonText: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonFooter,
    IonButtons,
    IonButton,
    IonInput,
    IonLabel,
    IonList,
    IonItem,
    IonBackButton,
    IonText,
  },
  setup() {
    const router = useRouter();
    const searchText = ref('');
    const showMore = ref(false);

    return {
      router,
      searchText,
      showMore,
    };
  },
  mounted() {
    this.showMore = false;
  },
  unmounted() {
    this.showMore = false;
  },
  watch: {
    showMore(newValue) {
      this.$emit('showMoreChanged', newValue);
    },
  },
  methods: {
    startScan() {
      this.router.push({ name: 'scan' });
    },
    onSearchTextChange() {
      this.$emit('searchTextChanged', this.searchText);
    },
    clickSwitchLang() {
      if (this.$i18n.locale == 'en') {
        this.$i18n.locale = 'zh';
      } else {
        this.$i18n.locale = 'en';
      }
      Storage.set({
        key: 'user_language',
        value: this.$i18n.locale,
      });
    },
    clickMore() {
      this.showMore = !this.showMore;
    },
    clickPage() {
      if (this.showMore) {
        this.showMore = false;
      }
    },
  },
};
</script>
<style scoped>
.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}
.marginLeft-10px {
  margin-left: 10px;
}
.search-bar {
  background: #F8F8F8;
  border-radius: 50px;
  padding-left: 20px;
}
.mag_glass {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/mag_glass.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: fit;
}
.scanQR {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/scanQR_2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.scanQR-wallet {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/scan_2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.height-65 {
  height: 65px !important;
}
.wallet_header ion-toolbar {
  --background: url('/assets/image/wallet_top1.png'); 
}
.wallet-leave-active {
  transition: height 0.2s;
}
.wallet-leave-to {
  height: 0;
}
.wallet_name {
  font-weight: 600;
  color:white;
  position: absolute; /* 绝对定位 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 将元素的中心点定位在图片的中心 */
}
.wallet_scan {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/scan_2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mines_header ion-toolbar {
  --background: transparent; 
}
.mines_content {
  --background: transparent; 
}
.mine_bg {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: -30px;
  background-image: url('/assets/image/mine_bg.png');
  background-size: 100% 100%; /* 图片尺寸适应 div 元素 */
  background-position: center; /* 图片在 div 中居中对齐 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
}
.pointmall_bg {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: -30px;
  background-image: url('/assets/image/point_mall_bg.png');
  background-size: 100% 100%; /* 图片尺寸适应 div 元素 */
  background-position: center; /* 图片在 div 中居中对齐 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
}
.mine_switch_lang {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/switch_lang.svg');
}
.mine_more {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/more.png');
}
.dropdown-menu {
  position: absolute;
  top: 100%; /* Position just below the button */
  right: 0; /* Align to the right of the button */
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000; /* Ensure it's above other content */
  width: 200px; /* Adjust as needed */
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f8f8f8;
}
.white-back-button {
  background-image:  url('/assets/image/back_white.png');
  background-size: fit;
  background-repeat: no-repeat;
  background-position: left center;
  min-width: 32px;
  margin-left: 16px;
}
ion-back-button::part(text) {
  font-weight: 700;
  font-size: 1.1rem;
  margin-left: 18px;
  padding-bottom: 3px;
}
.back-button-text-white::part(text) {
  color: #ffffff;
}
</style>